const firebaseConfig = {
  projectId: "watsonia-village",
  appId: "1:518649101401:web:0905e187887a6734aac548",
  databaseURL:
    "https://watsonia-village-default-rtdb.europe-west1.firebasedatabase.app",
  storageBucket: "watsonia-village.appspot.com",
  locationId: "europe-west",
  apiKey: "AIzaSyDZcFq-uzLprsZNHfw6ukCKpDeNQUd4Z6s",
  authDomain: "watsonia-village.firebaseapp.com",
  messagingSenderId: "518649101401",
  measurementId: "G-KYBBQ56LLX",
};

export default firebaseConfig;
